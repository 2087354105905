import axios from "axios";
import { useEffect, useState } from "react";
import { currentActiveAwsStoragesVar, currentActiveServersVar } from "../App";
import { BACKEND_SERVERS, ServerLocation } from "./servers.types";

/*async function checkDatabaseHealth(serverUrl: string) {
	try {
		const response = await axios.get(`${serverUrl}/health/database`, {
			timeout: 5000,
		});
		return response.status === 200; // Server is healthy
	} catch (error) {
		console.error(
			`Health check failed for ${serverUrl}:`,
			typeof error === "object" && error && "message" in error
				? error.message
				: "unknown"
		);
		return false; // Server is down
	}
}*/

async function checkServerHealth(serverUrl: string) {
	try {
		const response = await axios.get(`${serverUrl}/health/server`, {
			timeout: 5000,
		});
		return response.status === 200; // Server is healthy
	} catch (error) {
		console.error(
			`Health check failed for ${serverUrl}:`,
			typeof error === "object" && error && "message" in error
				? error.message
				: "unknown"
		);
		return false; // Server is down
	}
}

async function checkAwsHealth(awsUrl?: string) {
	if (!awsUrl) {
		return false;
	}
	try {
		const response = await axios.get(`${awsUrl}/assets/intro3.webp`, {
			timeout: 5000,
		});
		return response.status === 200; // Server is healthy
	} catch (error) {
		console.log(
			`Health check failed for ${awsUrl}:`,
			typeof error === "object" && error && "message" in error
				? error.message
				: "unknown"
		);
		return false; // Server is down
	}
}

export const useCheckHealthyServers = () => {
	// check servers
	useEffect(() => {
		const init = async () => {
			const healthyOnes: ServerLocation[] = [];
			for (const server of BACKEND_SERVERS) {
				const isHealthy = await checkServerHealth(server.url);
				if (isHealthy) {
					healthyOnes.push(server.location);
				}
			}

			currentActiveServersVar([...healthyOnes]);
		};

		init();

		const interval = setInterval(init, 30000); // Call every 10 seconds

		return () => {
			clearInterval(interval);
			// isUnMounted = true;
		};
	}, []);

	// check aws storages
	useEffect(() => {
		const init = async () => {
			const healthyOnes: ServerLocation[] = [];
			for (const server of BACKEND_SERVERS) {
				const isHealthy = await checkAwsHealth(server.aws);
				if (isHealthy) {
					healthyOnes.push(server.location);
				}
			}

			currentActiveAwsStoragesVar([...healthyOnes]);
		};

		init();

		const interval = setInterval(init, 30000); // Call every 10 seconds

		return () => {
			clearInterval(interval);
			// isUnMounted = true;
		};
	}, []);
};
