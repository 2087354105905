export const SERVER_URL = process.env.REACT_APP_API_URI;
export const IR_SERVER_URL = process.env.REACT_APP_IR_API_URI;
export const CLIENT_URL = process.env.REACT_APP_CLIENT_URI;
export const AWS3_BASE_URL = process.env.REACT_APP_AWS3_BASE_URL;
export const AWS3_IR_BASE_URL = process.env.REACT_APP_AWS3_IR_BASE_URL;
export const GRAPHQL_URL = `${SERVER_URL}/graphql`;
export const COURSE_IMAGES_FOLDER = process.env.REACT_APP_COURSE_IMAGES_FOLDER;
export const PROFILE_IMAGES_FOLDER =
	process.env.REACT_APP_PROFILE_IMAGES_FOLDER;
export const NOTE_IMAGES_FOLDER = process.env.REACT_APP_NOTE_IMAGES_FOLDER;
export const LESSON_MEDIA_FOLDER = process.env.REACT_APP_LESSON_MEDIA_FOLDER;
export const ASSETS_FOLDER = process.env.REACT_APP_ASSETS_FOLDER;
export const NEED_TO_UPGRADE_DATABASE =
	process.env.REACT_APP_NEED_TO_UPGRADE_DATABASE;
export const APP_VERSION = process.env.REACT_APP_VERSION;

export const METAORA_SITE_LINK = "https://metaora.app";
