import {
	AWS3_BASE_URL,
	AWS3_IR_BASE_URL,
	IR_SERVER_URL,
	SERVER_URL,
} from "../config";

// in Iran server
export const IRAN_SPECIFIC_OPERATIONS: string[] = [];

// this operations (resolvers) just need to call
// in abroad server
export const ABROAD_SPECIFIC_OPERATIONS: string[] = [
	"RegisterLesson",
	"UploadLessonMedia",
	"YoutubeLessonMedia",
	"UploadMultiMedia",
	"CreateCampaign",
	"UpdateCampaign",
	"PauseCampaign",
	"DeleteCampaign",
	"GetOxfordWord",
	"SearchOxfordWords",
	"TextToSpeechReq",
	// we should check these as well
	/*"signUpWithGoogle",
	"signInWithGoogle",
	"signUpWithApple",
	"signInWithApple",*/
];

export const IRAN_SERVER_LOCATION = "iran";

export const ABROAD_SERVER_LOCATION = "abroad";

export type ServerLocation =
	| typeof IRAN_SERVER_LOCATION
	| typeof ABROAD_SERVER_LOCATION;

export const BACKEND_SERVERS = [
	{
		url: SERVER_URL || "",
		location: ABROAD_SERVER_LOCATION,
		aws: AWS3_BASE_URL,
	},
	{
		url: IR_SERVER_URL || "",
		location: IRAN_SERVER_LOCATION,
		aws: AWS3_IR_BASE_URL,
	},
] as const;
