import { useState, useEffect } from "react";
import { getUserCountry } from "../helper/user-location.utility";
import { currentCountryVar } from "../App";

export function useLocationChecker() {
	useEffect(() => {
		let isUnMounted = false;

		async function fetchLocation() {
			if (isUnMounted) {
				return;
			}
			try {
				const country = await getUserCountry();

				if (country) {
					currentCountryVar(country.name);
				}
			} catch (err) {
				console.log(err);
			}
		}

		fetchLocation();

		const interval = setInterval(fetchLocation, 30000); // Call every 30 seconds

		return () => {
			clearInterval(interval);
			isUnMounted = true;
		};
	}, []);

	return null;
}
