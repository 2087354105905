import React, { Suspense, lazy, useEffect } from "react";
import {
	BrowserRouter as Router,
	Switch,
	Redirect,
	Route,
} from "react-router-dom";
import Loading from "../components/loading/Loading";
import ReactNativeDeepLink from "../reactNative/deepLink/ReactNativeDeepLink";
import PublicRoute from "./PublicRoute";
import RestrictedRoute from "./RestrictedRoute";
import { RoutesConfig } from "./RoutesConfig";

const Home = lazy(() => import("../containers/home/Home"));

const Profile = lazy(() => import("../containers/profile/Profile"));
const ChangePassword = lazy(
	() => import("../containers/changePassword/ChangePassword")
);
const DeleteAccount = lazy(
	() => import("../containers/deleteAccount/DeleteAccount")
);

const textToSpeechSettings = lazy(
	() => import("../containers/settings/textToSpeech/TextToSpeechSettings")
);

const notificationsSettings = lazy(
	() => import("../containers/settings/notifications/NotificationsSettings")
);

const AddCourse = lazy(() => import("../containers/addCourse/AddCourse"));
const EditCourse = lazy(
	() => import("../containers/editCourse/EditCoursePage")
);
const CourseList = lazy(
	() => import("../containers/courseList/CourseListPage")
);
const Course = lazy(() => import("../containers/course/Course"));

const SubcourseListPage = lazy(
	() => import("../containers/subcourse/list/SubcourseListPage")
);
const SubcourseAddPage = lazy(
	() => import("../containers/subcourse/add/SubcourseAddPage")
);
const SubcourseEditPage = lazy(
	() => import("../containers/subcourse/edit/SubcourseEditPage")
);

const ChapterListPage = lazy(
	() => import("../containers/chapter/list/ChapterListPage")
);
const ChapterAddPage = lazy(
	() => import("../containers/chapter/add/ChapterAddPage")
);
const ChapterEditPage = lazy(
	() => import("../containers/chapter/edit/ChapterEditPage")
);

const LessonListPage = lazy(
	() => import("../containers/lesson/list/LessonListPage")
);
const AddLesson = lazy(() => import("../containers/lesson/add/AddLesson"));
const Subtitle = lazy(() => import("../containers/lesson/subtitle/Subtitle"));
const EditLesson = lazy(() => import("../containers/lesson/edit/EditLesson"));
const SingleLesson = lazy(
	() => import("../containers/lesson/singleLesson/SingleLesson")
);

const MainLesson = lazy(() => import("../containers/lesson/main/Main"));
const Speaking = lazy(() => import("../containers/lesson/speaking/Speaking"));
const Vocabulary = lazy(
	() => import("../containers/lesson/vocabulary/Vocabulary")
);

const VocabList = lazy(
	() => import("../containers/lesson/vocab-list/VocabList")
);
const Writing = lazy(() => import("../containers/lesson/writing/Writing"));
const OrderGame = lazy(
	() => import("../containers/lesson/orderGame/OrderGame")
);

const Review = lazy(() => import("../containers/review/Review"));
const ReviewList = lazy(
	() => import("../containers/review/list/ReviewListPage")
);

const Login = lazy(() => import("../containers/login/Login"));

const SignUp = lazy(() => import("../containers/signUp/SignUpPage"));

const SelectAffiliate = lazy(
	() => import("../containers/affiliate/select-affiliate/SelectAffiliatePage")
);
const Affiliate = lazy(() => import("../containers/affiliate/AffiliatePage"));
const UpdateAffiliate = lazy(
	() => import("../containers/affiliate/update-affiliate/UpdateAffiliatePage")
);
const AffiliateBankInfo = lazy(
	() => import("../containers/affiliate/bank-info/BankInfoPage")
);
const AffiliatePaymentRequest = lazy(
	() =>
		import(
			"../containers/affiliate/payment-request-list/PaymentRequestPage"
		)
);
const AffiliateNewPaymentRequest = lazy(
	() =>
		import(
			"../containers/affiliate/payment-request-new/PaymentRequestNewPage"
		)
);
const AffiliateWithdraw = lazy(
	() => import("../containers/affiliate/withdraw/WithdrawPage")
);

const AffiliateReferrals = lazy(
	() => import("../containers/affiliate/referrals/ReferralsPage")
);

const AffiliateInvitedUsers = lazy(
	() => import("../containers/affiliate/invited-users/InvitedUsersPage")
);

const SubscriptionPlans = lazy(
	() =>
		import(
			"../containers/ecommerce/subscription-plans/SubscriptionPlansPage"
		)
);

const AdminUsers = lazy(
	() => import("../containers/@admin/users/AdminUsersPage")
);
const AdminAffiliates = lazy(
	() => import("../containers/@admin/affiliate/AdminAffiliatesPage")
);
const AdminAffiliatePaymentRequests = lazy(
	() =>
		import(
			"../containers/@admin/affiliate/payment-requests/AdminAffiliatePaymentRequestsPage"
		)
);
const AdminAffiliatePayments = lazy(
	() =>
		import(
			"../containers/@admin/affiliate/payments/AdminAffiliatePaymentsPage"
		)
);
const AdminAffiliateReferrals = lazy(
	() =>
		import(
			"../containers/@admin/affiliate/referrals/AdminAffiliateReferralsPage"
		)
);

const AdminSubscriptionPlans = lazy(
	() =>
		import(
			"../containers/@admin/ecommerce/subscription-plans/AdminSubscriptionPlansPage"
		)
);

const AdminCoupons = lazy(
	() => import("../containers/@admin/ecommerce/coupon/AdminCouponsPage")
);

const AdminTransactions = lazy(
	() =>
		import(
			"../containers/@admin/ecommerce/transaction/AdminTransactionsPage"
		)
);

const AdminPushNotifCampaigns = lazy(
	() =>
		import(
			"../containers/@admin/notification/push-notif-campaign/AdminPushNotifCampaignPage"
		)
);

const AdminInAppMessafingCampaigns = lazy(
	() =>
		import(
			"../containers/@admin/notification/in-app-messaging/AdminInAppMessagingCampaignPage"
		)
);

const AdminEmailCampaigns = lazy(
	() =>
		import(
			"../containers/@admin/notification/email-campaign/AdminEmailCampaignPage"
		)
);

const AdminSMSCampaigns = lazy(
	() =>
		import(
			"../containers/@admin/notification/sms-campaign/AdminSMSCampaignPage"
		)
);

const AdminNotifications = lazy(
	() =>
		import(
			"../containers/@admin/ecommerce/transaction/AdminTransactionsPage"
		)
);

const HelpCenter = lazy(() => import("../containers/helpCenter/HelpCenter"));
const StudentPlanning = lazy(
	() => import("../containers/studentPlanning/StudentPlanning")
);

const ReportBug = lazy(() => import("../containers/reportBug/ReportBug"));

const SendFeedback = lazy(
	() => import("../containers/sendFeedback/SendFeedback")
);

const PrivacyPolicy = lazy(
	() => import("../containers/privacyPolicy/PrivacyPolicy")
);
const TermsOfService = lazy(
	() => import("../containers/termsOfService/TermsOfService")
);

const CopyRightScreen = lazy(() => import("../containers/copyRight/CopyRight"));

const VerifyCode = lazy(
	() => import("../containers/verifyCode/VerifyCodePage")
);

const PhoneAuth = lazy(() => import("../containers/phoneAuth/PhoneAuthPage"));
const PhoneSignIn = lazy(
	() => import("../containers/phoneAuth/PhoneSignInPage")
);

const EmailConfirmation = lazy(
	() => import("../containers/emailConfirmation/EmailConfirmation")
);

const Language = lazy(() => import("../containers/language/Language"));

const ForgetPassword = lazy(
	() => import("../containers/forgetPassword/ForgetPassword")
);

const ResetPassword = lazy(
	() => import("../containers/resetPassword/ResetPassword")
);

const Walkthrough = lazy(() => import("../containers/walkthrough/Walkthrough"));

const UITest = lazy(() => import("../containers/uiTest/UITest"));
//const UITest = lazy(() => import("../screens/streak/Streak"));

const Dictionary = lazy(
	() => import("../containers/dictionary/DictionaryPage")
);
const DictionarySinglePage = lazy(
	() => import("../containers/dictionary/single/DictionarySinglePage")
);

const NotificationListPage = lazy(
	() => import("../containers/notification/NotificationListPage")
);
const NotificationSinglePage = lazy(
	() => import("../containers/notification/NotificationSinglePage")
);

const Routes = () => {
	return (
		<Router>
			<ReactNativeDeepLink />
			<Suspense fallback={<Loading isLoading />}>
				<Switch>
					<RestrictedRoute
						exact
						path={RoutesConfig.home.path}
						component={Home}
					/>
					<RestrictedRoute
						exact
						path={RoutesConfig.profile.root.path}
						component={Profile}
					/>
					<RestrictedRoute
						exact
						path={RoutesConfig.profile.changePassword.path}
						component={ChangePassword}
					/>
					<RestrictedRoute
						exact
						path={RoutesConfig.profile.deleteAccount.path}
						component={DeleteAccount}
					/>

					<RestrictedRoute
						exact
						path={RoutesConfig.settings.textToSpeech.path}
						component={textToSpeechSettings}
					/>
					<RestrictedRoute
						exact
						path={RoutesConfig.settings.notifications.path}
						component={notificationsSettings}
					/>

					<RestrictedRoute
						exact
						path={RoutesConfig.course.list.path}
						component={CourseList}
					/>
					<RestrictedRoute
						exact
						path={RoutesConfig.course.add.path}
						access="createCourse"
						component={AddCourse}
					/>
					<RestrictedRoute
						exact
						path={RoutesConfig.course.edit.path}
						component={EditCourse}
					/>
					{/*<RestrictedRoute exact path="/course/:courseId" component={Course} />
					 */}
					<RestrictedRoute
						exact
						path={RoutesConfig.subcourse.list.path}
						component={SubcourseListPage}
					/>
					<RestrictedRoute
						exact
						path={RoutesConfig.subcourse.add.path}
						component={SubcourseAddPage}
					/>
					<RestrictedRoute
						exact
						path={RoutesConfig.subcourse.edit.path}
						component={SubcourseEditPage}
					/>
					<RestrictedRoute
						exact
						path={RoutesConfig.chapter.list.path}
						component={ChapterListPage}
					/>
					<RestrictedRoute
						exact
						path={RoutesConfig.chapter.add.path}
						component={ChapterAddPage}
					/>
					<RestrictedRoute
						exact
						path={RoutesConfig.chapter.edit.path}
						component={ChapterEditPage}
					/>
					<RestrictedRoute
						exact
						path={RoutesConfig.lesson.list.path}
						component={LessonListPage}
					/>
					<RestrictedRoute
						exact
						path={RoutesConfig.lesson.add.path}
						component={AddLesson}
					/>
					<RestrictedRoute
						exact
						path={RoutesConfig.lesson.subtitle.path}
						component={Subtitle}
					/>
					<RestrictedRoute
						exact
						path={RoutesConfig.lesson.edit.path}
						component={EditLesson}
					/>
					<RestrictedRoute
						exact
						path={RoutesConfig.lesson.single.path}
						component={SingleLesson}
					/>
					<RestrictedRoute
						exact
						path={RoutesConfig.lesson.main.path}
						component={MainLesson}
					/>
					<RestrictedRoute
						exact
						path={RoutesConfig.lesson.speaking.path}
						component={Speaking}
					/>
					<RestrictedRoute
						exact
						path={RoutesConfig.lesson.vocabulary.path}
						component={Vocabulary}
					/>
					<RestrictedRoute
						exact
						path={RoutesConfig.lesson.vocabList.path}
						component={VocabList}
					/>
					<RestrictedRoute
						exact
						path={RoutesConfig.lesson.writing.path}
						component={Writing}
					/>
					<RestrictedRoute
						exact
						path={RoutesConfig.lesson.orderGame.path}
						component={OrderGame}
					/>
					<RestrictedRoute
						exact
						path={RoutesConfig.review.root.path}
						component={ReviewList}
					/>
					<RestrictedRoute
						exact
						path={RoutesConfig.review.lesson.path}
						component={Review}
					/>
					<RestrictedRoute exact path="/test" component={UITest} />
					<RestrictedRoute
						exact
						path={RoutesConfig.dictionary.root.path}
						component={Dictionary}
					/>
					<RestrictedRoute
						exact
						path={RoutesConfig.dictionary.word.path}
						component={DictionarySinglePage}
					/>
					<RestrictedRoute
						exact
						path="/select-affiliate"
						component={SelectAffiliate}
					/>

					<RestrictedRoute
						exact
						path={RoutesConfig.notification.list.path}
						component={NotificationListPage}
					/>

					<RestrictedRoute
						exact
						path={RoutesConfig.notification.single.path}
						component={NotificationSinglePage}
					/>
					{/**
					 * /affiliate
					 * /affiliate/update-title
					 * /affiliate/bank-info
					 * /affiliate/payment-request
					 * /affiliate/payment-request/new
					 * /affiliate/withdraw
					 * /affiliate/invited-users
					 * /affiliate/referrals
					 */}
					<RestrictedRoute
						exact
						path="/affiliate"
						component={Affiliate}
					/>
					<RestrictedRoute
						exact
						path="/affiliate/update-title"
						component={UpdateAffiliate}
					/>
					<RestrictedRoute
						exact
						path="/affiliate/bank-info"
						component={AffiliateBankInfo}
					/>
					<RestrictedRoute
						exact
						path="/affiliate/payment-request"
						component={AffiliatePaymentRequest}
					/>
					<RestrictedRoute
						exact
						path="/affiliate/payment-request/new"
						component={AffiliateNewPaymentRequest}
					/>
					<RestrictedRoute
						exact
						path="/affiliate/withdraw"
						component={AffiliateWithdraw}
					/>
					<RestrictedRoute
						exact
						path="/affiliate/referrals"
						component={AffiliateReferrals}
					/>
					<RestrictedRoute
						exact
						path="/affiliate/invited-users"
						component={AffiliateInvitedUsers}
					/>

					<RestrictedRoute
						exact
						path="/subscription-plans"
						component={SubscriptionPlans}
					/>
					{/**
					 * Admin routes
					 * /admin/users
					 *
					 * affiliate:
					 * /admin/affiliates
					 * /admin/affiliate/edit/:id
					 * /admin/affiliate/payment-requests
					 * /admin/affiliate/payments
					 * /admin/affiliate/referrals
					 *
					 * ecommerce:
					 * /admin/ecommerce/subscription-plans
					 */}
					<RestrictedRoute
						exact
						path="/admin/users"
						component={AdminUsers}
						access={"readOtherUsers"}
					/>
					<RestrictedRoute
						exact
						path="/admin/affiliates"
						component={AdminAffiliates}
						access={"readOtherUsers"}
					/>
					<RestrictedRoute
						exact
						path="/admin/affiliate/payment-requests"
						component={AdminAffiliatePaymentRequests}
						access={"readOtherUsers"}
					/>
					<RestrictedRoute
						exact
						path="/admin/affiliate/payments"
						component={AdminAffiliatePayments}
						access={"readOtherUsers"}
					/>
					<RestrictedRoute
						exact
						path="/admin/affiliate/referrals"
						component={AdminAffiliateReferrals}
						access={"readOtherUsers"}
					/>
					<RestrictedRoute
						exact
						path="/admin/ecommerce/subscription-plans"
						component={AdminSubscriptionPlans}
						access={"readOtherUsers"}
					/>

					<RestrictedRoute
						exact
						path="/admin/ecommerce/coupons"
						component={AdminCoupons}
						access={"readOtherUsers"}
					/>

					<RestrictedRoute
						exact
						path="/admin/ecommerce/transactions"
						component={AdminTransactions}
						access={"readOtherUsers"}
					/>

					<RestrictedRoute
						exact
						path="/admin/campaigns/push-notif"
						component={AdminPushNotifCampaigns}
						access={"readOtherUsers"}
					/>

					<RestrictedRoute
						exact
						path="/admin/campaigns/in-app-messaging"
						component={AdminInAppMessafingCampaigns}
						access={"readOtherUsers"}
					/>

					<RestrictedRoute
						exact
						path="/admin/campaigns/email"
						component={AdminEmailCampaigns}
						access={"readOtherUsers"}
					/>

					<RestrictedRoute
						exact
						path="/admin/campaigns/sms"
						component={AdminSMSCampaigns}
						access={"readOtherUsers"}
					/>

					<RestrictedRoute
						exact
						path="/admin/notifications"
						component={AdminNotifications}
						access={"readOtherUsers"}
					/>

					<Route exact path="/help-center" component={HelpCenter} />

					<RestrictedRoute
						exact
						path="/student-planning"
						component={StudentPlanning}
					/>

					<RestrictedRoute
						exact
						path="/report-bug"
						component={ReportBug}
					/>

					<RestrictedRoute
						exact
						path="/send-feedback"
						component={SendFeedback}
					/>

					<Route
						exact
						path="/privacy-policy"
						component={PrivacyPolicy}
					/>

					<Route
						exact
						path="/terms-of-service"
						component={TermsOfService}
					/>

					<Route
						exact
						path="/copyright"
						component={CopyRightScreen}
					/>

					<PublicRoute exact path="/login" component={Login} />
					<PublicRoute exact path="/sign-up" component={SignUp} />
					<PublicRoute
						exact
						path="/verify-code"
						component={VerifyCode}
					/>
					<PublicRoute
						exact
						path="/phone-auth"
						component={PhoneAuth}
					/>
					<PublicRoute
						exact
						path="/phone-login"
						component={PhoneSignIn}
					/>
					<PublicRoute
						exact
						path="/register/confirm/:email/:token"
						component={EmailConfirmation}
					/>
					<PublicRoute
						exact
						path="/forget-pass"
						component={ForgetPassword}
					/>
					<PublicRoute
						exact
						path="/reset/password/:token"
						component={ResetPassword}
					/>
					<PublicRoute
						exact
						path="/walkthrough"
						component={Walkthrough}
					/>
					<RestrictedRoute
						exact
						path="/language"
						component={Language}
					/>
					<Redirect to="/" />
				</Switch>
			</Suspense>
		</Router>
	);
};

export default Routes;
