import React, { useCallback, useEffect, useState } from "react";
import { useRNHandler } from "../@utility/react-native.utility";
import { useCBConfirmTransactionMutation } from "../../containers/ecommerce/graphQL/cb-confirm-transaction/cb-confirm-transaction.mutation";
import { useFailTransactionMutation } from "../../containers/ecommerce/graphQL/fail-transaction/fail-transaction.mutation";
import { openErrorNotification } from "../../helper/hooks/useErrorNotification";
import { useReactiveVar } from "@apollo/client";
import { isRTLVar, userInfoVar } from "../../App";
import { useHistory } from "react-router-dom";
import { translate } from "../../i18n";
import useUpdatedUser from "../../graphQL/user/hooks/useUpdatedUser";
import { DotLoading, Modal, Result } from "antd-mobile";

export const RNCafeBazaarPayment = () => {
	const { loading: cbConfirmTransactionLoading, cbConfirmTransaction } =
		useCBConfirmTransactionMutation();

	const userInfo = useReactiveVar(userInfoVar);

	const history = useHistory();

	const { refetch, loading } = useUpdatedUser();

	const [startRefetch, setStartRefetch] = useState(false);
	const [paymentStatus, setPaymentStatus] = useState<
		"error" | "success" | "waiting" | undefined
	>();

	useEffect(() => {
		if ((loading && startRefetch) || cbConfirmTransactionLoading) {
			setPaymentStatus("waiting");
		}

		if (!loading) {
			setStartRefetch(false);
		}
	}, [loading, startRefetch, cbConfirmTransactionLoading]);

	const CafeBazaarConfirmPayment = (
		purchaseToken: string,
		productId: string
	) => {
		cbConfirmTransaction(
			{
				purchaseToken,
				productId,
			},
			{
				onCompleted({ CBConfirmTransaction }) {
					if (CBConfirmTransaction.result && userInfo) {
						setStartRefetch(true);

						(async function () {
							try {
								await refetch();

								setPaymentStatus("success");

								//history.push("/");
							} catch (error) {
								setPaymentStatus("error");
							}
						})();
					} else {
						setPaymentStatus("error");
					}
				},
				onError(error) {
					setPaymentStatus("error");
				},
			}
		);
	};

	const cbSuccessPayment = useCallback(
		(payload: { purchaseToken: string; productId: string }) => {
			// fail to remove video
			// no need to do anything
			CafeBazaarConfirmPayment(payload.purchaseToken, payload.productId);
		},
		[]
	);

	useRNHandler("cbSuccessPayment", cbSuccessPayment);

	const { failTransaction, loading: failTransactionLoading } =
		useFailTransactionMutation();

	const isRTL = useReactiveVar(isRTLVar);

	const cbFailPayment = useCallback(
		(payload: { transactionId: string; description?: string }) => {
			let description = "";
			switch (payload.description) {
				case "Error: Item not found":
					description = translate("cbPayment.notFountError");
					break;
				case "Error: We can't communicate with Bazaar: Service is disconnected":
					description = translate("cbPayment.CommunicateError");
					break;
				case "Error: Bazaar is not installed":
					description = translate("cbPayment.installError");
					break;
				case "Error: SDK is not connected to bazaar!":
					description = translate("cbPayment.sdkError");
					break;
				case "Error: purchase canceled":
					description = translate("cbPayment.cancelError");
					break;
				case "Error: CB Subscription failed.":
				default:
					description = translate("cbPayment.generalError");
			}

			openErrorNotification(description, isRTL);

			failTransaction(
				{
					transactionId: payload.transactionId,
					description: payload.description,
				},
				{
					onCompleted({ failTransaction }) {
						if (failTransaction.result) {
							// do something here
						}
					},
				}
			);
		},
		[]
	);

	useRNHandler("cbFailPayment", cbFailPayment);

	const [visible, setVisible] = useState(false);

	useEffect(() => {
		if (paymentStatus) {
			setVisible(true);
		}
	}, [paymentStatus]);

	const successMsg = translate("cbPayment.success");
	const failMsg = translate("cbPayment.failed");
	const waitingMsg = translate("cbPayment.waiting");

	const successAction = {
		key: "goHome",
		text: translate("cbPayment.goHome"),
		onClick: () => {
			history.push("/");
			setPaymentStatus(undefined);
		},
	};

	const failAction = {
		key: "close",
		text: translate("cbPayment.close"),
		onClick: () => {
			setPaymentStatus(undefined);
		},
	};

	return (
		<Modal
			visible={visible}
			content={
				<Result
					status={paymentStatus}
					title={
						paymentStatus === "success" ? (
							successMsg
						) : paymentStatus === "error" ? (
							failMsg
						) : (
							<>
								<DotLoading /> {waitingMsg}
							</>
						)
					}
					// description=""
				/>
			}
			closeOnAction
			onClose={() => {
				setVisible(false);
			}}
			actions={
				paymentStatus !== "waiting"
					? [paymentStatus === "success" ? successAction : failAction]
					: []
			}
		/>
	);
};
