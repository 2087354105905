import {useEffect, useRef} from "react";

const usePrevious = <Value = any>(value: Value): Value | undefined => {
    const ref = useRef<Value>();

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
};

export default usePrevious;