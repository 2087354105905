import React, { CSSProperties, ReactNode, useEffect, useState } from "react";
import "./App.css";
import "./i18n";
import i18n from "i18n-js";
import {
	ApolloClient,
	InMemoryCache,
	ApolloProvider,
	makeVar,
	from,
	ApolloLink,
	ErrorPolicy,
	ReactiveVar,
	ServerParseError,
	ServerError,
	useReactiveVar,
	NormalizedCacheObject,
} from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { onError } from "@apollo/client/link/error";
import localforage from "localforage";
import { persistCache, LocalForageWrapper } from "apollo3-cache-persist";
import { notification } from "antd";
import { CloseOutline } from "antd-mobile-icons";
import Routes from "./routes/Routes";
import { GRAPHQL_URL, NEED_TO_UPGRADE_DATABASE, SERVER_URL } from "./config";
import { CurrentUser } from "./graphQL/user/UserTypes";
import Loading from "./components/loading/Loading";
import { NotificationPlacement } from "antd/lib/notification";
import {
	Course,
	SearchCourseData,
	SearchCourseArgs,
} from "./graphQL/course/CourseTypes";
import { GetDecksArgs, GetDecksData } from "./graphQL/flashcard/FlashcardTypes";
import {
	SearchSubCourseData,
	SubCourse,
} from "./graphQL/subCourse/SubCourseTypes";
import { Chapter, SearchChapterData } from "./graphQL/chapter/ChapterTypes";
import {
	Lesson,
	RewardedAds,
	SearchLessonData,
	Sentence,
	Word,
} from "./graphQL/lesson/LessonTypes";
//import { PullToRefresh } from "antd-mobile";
import { EmbededMedia } from "./graphQL/media/MediaTypes";
import AddLessonInBackground from "./containers/lesson/add/background/AddLessonInBackground";
import DatabaseMigration from "./database/DatabaseMigration";
import { Task } from "./graphQL/task/TaskTypes";
import ReactNativeBackButton from "./reactNative/backButton/ReactNativeBackButton";
import { IconWrapper } from "./theme/icons/Icon";
import { isIOS, isAndroid } from "react-device-detect";
import clsx from "clsx";
import {
	FromDictionary,
	NoteOrigin,
	NoteTranslationPart,
} from "./graphQL/note/NoteTypes";
import { getLessonPagesHeaders } from "./routes/RoutesConfig";
import ReactNativeForceUpdate from "./reactNative/forceUpdate/ReactNativeForceUpdate";
import ReactNativeSetCurrentUrl from "./reactNative/setCurrentUrl/ReactNativeSetCurrentUrl";
import { AdsErrorTypes } from "./types/general";
// import ReactNativeAdsError from "./reactNative/adsError/ReactNativeAdsError";
import ReactNativeReady from "./reactNative/ready/ReactNativeReady";
//import RnUserAchievementsCount from "./components/userAchievementsCount/RnUserAchievementsCount";
import ReactNativeTakeAction from "./reactNative/takeAction/ReactNativeTakeAction";
//import { useRewardAdsStorage } from "./useRewardAdsStorage";
import useReactNativeUserSync from "./reactNative/userSync/useReactNativeUserSync";
import { useLocation } from "react-router-dom";
import { getUserCountry } from "./helper/user-location.utility";
import {
	Affiliate,
	InvitedUser,
	PaymentBasic,
	PaymentRequest,
	ReferralForAffiliate,
} from "./containers/affiliate/affiliate.types";
import { translate } from "./i18n";
import { CheckLanguageChange } from "./i18n/CheckLanguageChange";
import "./reactNative/@utility/react-native.init";
import { initializeVideosDownloadInfo } from "./reactNative/video-downloader/video-downloader.query";
import { RNVideoDownloader } from "./reactNative/video-downloader/video-downloader";
import { useIncompleteRegister } from "./containers/signUp/incomplete-register/incomplete-register.hook";
import { GetUserNotificationsData } from "./containers/notification/graphQL/get-user-notifications/get-user-notifications.types";
import { Notification } from "./containers/@admin/notification/notification.types";
import { FindAffiliatePaymentsData } from "./containers/affiliate/graphQL/find-affiliate-payments/find-affiliate-payments.types";
import { FindInvitedUsersData } from "./containers/affiliate/graphQL/find-invited-users/find-invited-users.types";
import { FindPaymentRequestsData } from "./containers/affiliate/graphQL/find-payment-requests/find-payment-requests.types";
import { FindReferralsData } from "./containers/affiliate/graphQL/find-referrals/find-referrals.types";
import { RNTextToSpeechVoices } from "./containers/settings/textToSpeech/RNTextToSpeechVoices";
import { getUserTrackingInfo } from "./helper/user-tracking.utility";
import { useOptimalServerFactory } from "./services/@optimal-server-factory";
import {
	ABROAD_SERVER_LOCATION,
	IRAN_SERVER_LOCATION,
	ServerLocation,
} from "./services/servers.types";
import { selectTheBestServer } from "./services/operation-location-base";
/*import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";

if (process.env.NODE_ENV !== "development") {
	// Adds messages only in a dev environment
	loadDevMessages();
	loadErrorMessages();
}*/

async function hash(string: string) {
	const utf8 = new TextEncoder().encode(string);
	const hashBuffer = await crypto.subtle.digest("SHA-256", utf8);
	const hashArray = Array.from(new Uint8Array(hashBuffer));
	const hashHex = hashArray
		.map((bytes) => bytes.toString(16).padStart(2, "0"))
		.join("");
	return hashHex;
}

export const quizProgressVar = makeVar({
	total: 0,
	current: 0,
});

export const currentCountryVar = makeVar<string | null>(null);

export const currentActiveAwsStoragesVar = makeVar<ServerLocation[]>([
	IRAN_SERVER_LOCATION,
	ABROAD_SERVER_LOCATION,
]);

export const currentActiveServersVar = makeVar<ServerLocation[]>([
	IRAN_SERVER_LOCATION,
	ABROAD_SERVER_LOCATION,
]);

export const quizSubmitVar = makeVar(false);

export const currentAffiliateInfoVar = makeVar<Affiliate | null>(null);

export const isRTLVar = makeVar(false);

export const translationLanguageVar = makeVar("");

export const isPlayerReadyVar = makeVar(false);

export const playerPlayedSecondsTimeVar = makeVar(0);

export const playerCurrentFrameVar = makeVar(0);

export const playerSeekedWordVar = makeVar("");

export const playerAudioModeVar = makeVar(false);

export const isFrameByFramePlayingVar = makeVar(false);

export const isPlayerRepeatModeVar = makeVar(false);

export const isPlayerPlayingVar = makeVar(false);
export const isPlayerPlayingConfirmedVar = makeVar(false);

export const playerDurationVar = makeVar(0);

export const isPlayerMutedVar = makeVar(false);

export const playbackRateVar = makeVar(1);

export const subtitleWordEditingEndVar = makeVar(0);

export const subtitleLastFrameVar = makeVar<Record<string, number>>({});

export const playerSeekToVar = makeVar<number | null>(null);

export const sentenceTranslationVisibleVar = makeVar(true);

export const sentenceTranslationEditingVar = makeVar(false);

export const currentSentenceTranslationVar = makeVar("");

export const removeItemSheetVisibleVar = makeVar(false);
export const removeItemConfirmedVar = makeVar(false);
export const removeItemLoadingVar = makeVar(false);
export const removeItemIdVar = makeVar("");

export const downloadedVideoIsRemovingVar = makeVar(false);

export const activeCourseTabVar = makeVar(0);

export const currentFrameOfResetedPracticeVar = makeVar<number | null>(null);

export const onlyPremiumAccessVar = makeVar(false);

export type UnitRewardAds = RewardedAds & {
	lessonId: string;
	courseId: string;
	chapterId: string;
	subCourseId: string;
};

export const currentUserRewardedAdsVar = makeVar<UnitRewardAds[] | undefined>(
	undefined
);

type CurrentLessonRewardEarned = {
	earned: 0 | 1;
	lessonId: string;
	unit?: number;
};

export const currentLessonRewardEarnedVar = makeVar<
	CurrentLessonRewardEarned | undefined
>(undefined);

export const onRewardEarnedEventVar = makeVar<string>("");
export const onGoogleSignInTokenIdVar = makeVar<string>("");
export const onAppleSignInTokenIdVar = makeVar<string>("");

type OnPhoneSignInInfo = {
	fullname?: string | null;
	email?: string | null;
	phoneNumber: string;
	time: string;
	error?: string | boolean | null;
};

export const onPhoneSignInInfoVar = makeVar<OnPhoneSignInInfo | undefined>(
	undefined
);

/** START Add New Lesson */
export const lessonUploadingInfoVar = makeVar<{
	isStarted: boolean;
	file: null | File;
	languages: string[];
}>({
	isStarted: false,
	file: null,
	languages: [],
});

export const lessonFromUrlInfoVar = makeVar<{
	isStarted: boolean;
	url: string;
	start?: string;
	end?: string;
	languages: string[];
}>({
	isStarted: false,
	url: "",
	languages: [],
});

export const notifdelayAfterActionIdVar = makeVar<string | undefined>(
	undefined
);

export const lessonCreationStepVar = makeVar(1);

export const lessonUploadingProgressVar = makeVar(0);

export const lessonIsProcessingVar = makeVar(false);

export const lessonIsUploadingCancelVar = makeVar(false);

export const lessonIsProcessingDoneVar = makeVar(false);

export const lessonIsProcessingFailedVar = makeVar(false);

export const lessonNeedToRetryVar = makeVar(false);

export const pullToRefreshDisabledVar = makeVar(false);

export const lessonBasicInfoVar = makeVar<{
	name: string;
	description: string;
	image?: EmbededMedia;
}>({
	name: "",
	description: "",
	image: undefined,
});

export const lessonMainInfoVar = makeVar<Task>({
	id: "",
	status: "starting",
});

export const resetLessonCreation = () => {
	lessonUploadingInfoVar({
		isStarted: false,
		file: null,
		languages: [],
	});
	lessonFromUrlInfoVar({
		isStarted: false,
		url: "",
		languages: [],
	});
	lessonUploadingProgressVar(0);
	lessonCreationStepVar(1);
	lessonIsProcessingVar(false);
	lessonIsUploadingCancelVar(false);
	lessonIsProcessingDoneVar(false);
	lessonIsProcessingFailedVar(false);
	lessonNeedToRetryVar(false);
	lessonBasicInfoVar({
		name: "",
		description: "",
		image: undefined,
	});
	lessonMainInfoVar({
		id: "",
		status: "starting",
	});
};

/** END Add New Lesson */

export const textToSpeechAvailableVoicesVar = makeVar<
	{
		id: string;
		name: string;
		language: string;
	}[]
>([]);

export const userInfoVar: ReactiveVar<CurrentUser | undefined> = makeVar(
	undefined
) as ReactiveVar<CurrentUser | undefined>;

export type IncompleteRegistrationData = {
	phoneNumber?: string;
	userId: string;
	email?: string;
};

export const incompleteRegistrationVar =
	makeVar<IncompleteRegistrationData | null>(null);

export const lastVerifyRequestTimeVar = makeVar<number | null>(null);

export const lessonDictionaryCurrentWordVar = makeVar<string>("");
export const dictionaryNavigateToNewRouteVar = makeVar<boolean>(true);
export const dictionaryCanAddNoteVar = makeVar<boolean>(false);
export const vocabSheetActiveStepVar = makeVar<string>("");
export const vocabSheetOpenVar = makeVar<boolean>(false);
export const vocabSheetNoteIdVar = makeVar<string>("");
export const isRnAppVar = makeVar<boolean>(false);
export const vocabSheetCurrentSenseVar = makeVar<{
	definition: string;
	example: string;
	id?: string;
	entryWord: string;
} | null>(null);
export const vocabSheetStepsHistoryVar = makeVar<
	{
		id: string;
		type: "page" | "word";
		params?: {
			defaultWord?: string;
		};
	}[]
>([]);

export const noteFormReInitializedVar = makeVar<boolean>(false);
export const noteFormChangedValuesVar = makeVar<{
	expressionWords?: string[];
	baseForm?: string | undefined;
	definition?: string;
	example?: string | undefined;
	original?: NoteOrigin[];
	images?: EmbededMedia[];
} | null>(null);
export const noteFormCurrentStatesVar = makeVar<{
	selectedNoteTranslation?: {
		expression: NoteTranslationPart[];
		definition: NoteTranslationPart[];
		example: NoteTranslationPart[];
	};
	selectedNoteId?: string | null;
	fromDictionaryInfo?: FromDictionary | null;
} | null>(null);

export const adsErrorSheetOpenVar = makeVar<boolean>(false);
export const adsErrorTypeVar = makeVar<AdsErrorTypes>("noError");

export const currentUnitVar = makeVar<number>(1);
export const gainingStarModalVisibleVar = makeVar<boolean>(false);
export const reachingDailyGoalPendingVar = makeVar<boolean>(false);
export const userCurrentTotalStarsVar = makeVar<number>(0);

export const cache = new InMemoryCache({
	typePolicies: {
		Query: {
			fields: {
				quizProgress: {
					read() {
						return quizProgressVar();
					},
				},
				translationLanguage: {
					read() {
						return translationLanguageVar();
					},
				},
				sentenceTranslationVisible: {
					read() {
						return sentenceTranslationVisibleVar();
					},
				},
				quizSubmit: {
					read() {
						return quizSubmitVar();
					},
				},
				currentCountry: {
					read() {
						return currentCountryVar();
					},
				},
				currentActiveServers: {
					read() {
						return currentActiveServersVar();
					},
				},
				currentActiveAwsStorages: {
					read() {
						return currentActiveAwsStoragesVar();
					},
				},
				currentAffiliateInfo: {
					read() {
						return currentAffiliateInfoVar();
					},
				},
				isRTL: {
					read() {
						return isRTLVar();
					},
				},
				isPlayerReady: {
					read() {
						return isPlayerReadyVar();
					},
				},
				playerPlayedSecondsTime: {
					read() {
						return playerPlayedSecondsTimeVar();
					},
				},
				playerDuration: {
					read() {
						return playerDurationVar();
					},
				},
				playerCurrentFrame: {
					read() {
						return playerCurrentFrameVar();
					},
				},
				playerSeekedWord: {
					read() {
						return playerSeekedWordVar();
					},
				},
				playerAudioMode: {
					read() {
						return playerAudioModeVar();
					},
				},
				isFrameByFramePlaying: {
					read() {
						return isFrameByFramePlayingVar();
					},
				},
				isPlayerRepeatMode: {
					read() {
						return isPlayerRepeatModeVar();
					},
				},
				isPlayerPlaying: {
					read() {
						return isPlayerPlayingVar();
					},
				},
				isPlayerPlayingConfirmed: {
					read() {
						return isPlayerPlayingConfirmedVar();
					},
				},
				playerSeekTo: {
					read() {
						return playerSeekToVar();
					},
				},
				isPlayerMuted: {
					read() {
						return isPlayerMutedVar();
					},
				},
				playbackRate: {
					read() {
						return playbackRateVar();
					},
				},
				subtitleWordEditingEnd: {
					read() {
						return subtitleWordEditingEndVar();
					},
				},
				subtitleLastFrame: {
					read() {
						return subtitleLastFrameVar();
					},
				},
				sentenceTranslationEditing: {
					read() {
						return sentenceTranslationEditingVar();
					},
				},
				currentSentenceTranslation: {
					read() {
						return currentSentenceTranslationVar();
					},
				},
				removeItemSheetVisible: {
					read() {
						return removeItemSheetVisibleVar();
					},
				},
				removeItemConfirmed: {
					read() {
						return removeItemConfirmedVar();
					},
				},
				removeItemLoading: {
					read() {
						return removeItemLoadingVar();
					},
				},
				removeItemId: {
					read() {
						return removeItemIdVar();
					},
				},
				downloadedVideoIsRemoving: {
					read() {
						return downloadedVideoIsRemovingVar();
					},
				},
				userInfo: {
					read() {
						return userInfoVar();
					},
				},
				//Add New Lesson === uploading
				lessonUploadingInfo: {
					read() {
						return lessonUploadingInfoVar();
					},
				},
				//Add New Lesson === from url
				lessonFromUrlInfo: {
					read() {
						return lessonFromUrlInfoVar();
					},
				},
				lessonUploadingProgress: {
					read() {
						return lessonUploadingProgressVar();
					},
				},
				lessonIsProcessing: {
					read() {
						return lessonIsProcessingVar();
					},
				},
				lessonIsUploadingCancel: {
					read() {
						return lessonIsUploadingCancelVar();
					},
				},
				lessonIsProcessingDone: {
					read() {
						return lessonIsProcessingDoneVar();
					},
				},
				lessonBasicInfo: {
					read() {
						return lessonBasicInfoVar();
					},
				},
				lessonMainInfo: {
					read() {
						return lessonMainInfoVar();
					},
				},
				notifdelayAfterActionId: {
					read() {
						return notifdelayAfterActionIdVar();
					},
				},
				lessonCreationStep: {
					read() {
						return lessonCreationStepVar();
					},
				},
				lessonIsProcessingFailed: {
					read() {
						return lessonIsProcessingFailedVar();
					},
				},
				lessonNeedToRetry: {
					read() {
						return lessonNeedToRetryVar();
					},
				},
				textToSpeechAvailableVoices: {
					read() {
						return textToSpeechAvailableVoicesVar();
					},
				},
				incompleteRegistration: {
					read() {
						return incompleteRegistrationVar();
					},
				},
				lastVerifyRequestTime: {
					read() {
						return lastVerifyRequestTimeVar();
					},
				},
				lessonDictionaryCurrentWord: {
					read() {
						return lessonDictionaryCurrentWordVar();
					},
				},
				dictionaryNavigateToNewRoute: {
					read() {
						return dictionaryNavigateToNewRouteVar();
					},
				},
				dictionaryCanAddNote: {
					read() {
						return dictionaryCanAddNoteVar();
					},
				},
				vocabSheetActiveStep: {
					read() {
						return vocabSheetActiveStepVar();
					},
				},
				vocabSheetOpen: {
					read() {
						return vocabSheetOpenVar();
					},
				},
				vocabSheetNoteId: {
					read() {
						return vocabSheetNoteIdVar();
					},
				},
				isRnApp: {
					read() {
						return isRnAppVar();
					},
				},
				vocabSheetCurrentSense: {
					read() {
						return vocabSheetCurrentSenseVar();
					},
				},
				vocabSheetStepsHistory: {
					read() {
						return vocabSheetStepsHistoryVar();
					},
				},
				noteFormReInitialized: {
					read() {
						return noteFormReInitializedVar();
					},
				},
				noteFormChangedValues: {
					read() {
						return noteFormChangedValuesVar();
					},
				},
				noteFormCurrentStates: {
					read() {
						return noteFormCurrentStatesVar();
					},
				},
				pullToRefreshDisabled: {
					read() {
						return pullToRefreshDisabledVar();
					},
				},

				activeCourseTab: {
					read() {
						return activeCourseTabVar();
					},
				},

				currentFrameOfResetedPractice: {
					read() {
						return currentFrameOfResetedPracticeVar();
					},
				},

				onlyPremiumAccess: {
					read() {
						return onlyPremiumAccessVar();
					},
				},

				currentUserRewardedAds: {
					read() {
						return currentUserRewardedAdsVar();
					},
				},

				currentLessonRewardEarned: {
					read() {
						return currentLessonRewardEarnedVar();
					},
				},

				onRewardEarnedEvent: {
					read() {
						return onRewardEarnedEventVar();
					},
				},

				onGoogleSignInTokenId: {
					read() {
						return onGoogleSignInTokenIdVar();
					},
				},

				onAppleSignInTokenId: {
					read() {
						return onAppleSignInTokenIdVar();
					},
				},

				onPhoneSignInInfo: {
					read() {
						return onPhoneSignInInfoVar();
					},
				},

				adsErrorSheetOpen: {
					read() {
						return adsErrorSheetOpenVar();
					},
				},

				adsErrorType: {
					read() {
						return adsErrorTypeVar();
					},
				},

				currentUnit: {
					read() {
						return currentUnitVar();
					},
				},

				gainingStarModalVisible: {
					read() {
						return gainingStarModalVisibleVar();
					},
				},

				reachingDailyGoalPending: {
					read() {
						return reachingDailyGoalPendingVar();
					},
				},

				userCurrentTotalStars: {
					read() {
						return userCurrentTotalStarsVar();
					},
				},

				searchCourse: {
					keyArgs: [
						"ids",
						"name",
						"description",
						"users",
						"type",
						"status",
						"resultType",
						"sort",
						"sortBy",
						"s",
						"supportedLevels",
					],
					merge(
						existing: SearchCourseData["searchCourse"],
						incoming: SearchCourseData["searchCourse"],
						options: any
					) {
						const { readField, args } = options;
						// Slicing is necessary because the existing data is
						// immutable, and frozen in development.
						const merged: Course[] = existing?.courses
							? existing.courses.slice(0)
							: [];
						// Obtain a Set of all existing course IDs.
						const existingIdSet = new Set(
							merged.map((course) => readField("id", course))
						);

						const newCourses =
							incoming.courses?.filter(
								(course) =>
									!existingIdSet.has(readField("id", course))
							) || [];

						if (!args?.first || args.first === 0) {
							merged.unshift(...newCourses);
						} else {
							merged.push(...newCourses);
						}

						return {
							...incoming,
							courses: merged,
						};
					},
				},

				/*getLessons: {
					keyArgs: ["type", "output", "chapterId", "sort", "sortBy"],
					merge(
						existing: GetDecksData["getLessons"],
						incoming: GetDecksData["getLessons"],
						options: any
					) {
						const { readField, args } = options;
						// Slicing is necessary because the existing data is
						// immutable, and frozen in development.
						const merged: Lesson[] = existing?.lessons
							? existing.lessons.slice(0)
							: [];
						// Obtain a Set of all existing course IDs.
						const existingIdSet = new Set(
							merged.map((course) => readField("id", course))
						);

						const newLessons =
							incoming.lessons?.filter(
								(lesson) =>
									!existingIdSet.has(readField("id", lesson))
							) || [];

						if (!args?.first || args.first === 0) {
							merged.unshift(...newLessons);
						} else {
							merged.push(...newLessons);
						}

						return {
							...incoming,
							lessons: merged,
						};
					},
				},*/

				searchSubCourse: {
					keyArgs: [
						"courseId",
						"ids",
						"name",
						"description",
						"users",
						"sort",
						"sortBy",
						"s",
					],
					merge(
						existing: SearchSubCourseData["searchSubCourse"],
						incoming: SearchSubCourseData["searchSubCourse"],
						options: any
					) {
						const { readField, args } = options;
						// Slicing is necessary because the existing data is
						// immutable, and frozen in development.
						const merged: SubCourse[] = existing?.subCourses
							? existing.subCourses.slice(0)
							: [];
						// Obtain a Set of all existing subcourse IDs.
						const existingIdSet = new Set(
							merged.map((subCourse) =>
								readField("id", subCourse)
							)
						);

						const newSubCourses =
							incoming.subCourses?.filter(
								(subCourse) =>
									!existingIdSet.has(
										readField("id", subCourse)
									)
							) || [];

						if (!args?.first || args.first === 0) {
							merged.unshift(...newSubCourses);
						} else {
							merged.push(...newSubCourses);
						}

						return {
							...incoming,
							subCourses: merged,
						};
					},
				},

				searchChapter: {
					keyArgs: [
						"courseId",
						"subCourseId",
						"ids",
						"name",
						"description",
						"users",
						"sort",
						"sortBy",
						"s",
					],
					merge(
						existing: SearchChapterData["searchChapter"],
						incoming: SearchChapterData["searchChapter"],
						options: any
					) {
						const { readField, args } = options;
						// Slicing is necessary because the existing data is
						// immutable, and frozen in development.
						const merged: Chapter[] = existing?.chapters
							? existing.chapters.slice(0)
							: [];
						// Obtain a Set of all existing chapter IDs.
						const existingIdSet = new Set(
							merged.map((chapter) => readField("id", chapter))
						);

						const newChapters =
							incoming.chapters?.filter(
								(chapter) =>
									!existingIdSet.has(readField("id", chapter))
							) || [];

						if (!args?.first || args.first === 0) {
							merged.unshift(...newChapters);
						} else {
							merged.push(...newChapters);
						}

						return {
							...incoming,
							chapters: merged,
						};
					},
				},

				searchLesson: {
					keyArgs: [
						"courseId",
						"subCourseId",
						"chapterId",
						"ids",
						"name",
						"description",
						"users",
						"sort",
						"sortBy",
						"s",
					],
					merge(
						existing: SearchLessonData["searchLesson"],
						incoming: SearchLessonData["searchLesson"],
						options: any
					) {
						const { readField, args } = options;
						// Slicing is necessary because the existing data is
						// immutable, and frozen in development.
						const merged: Lesson[] = existing?.lessons
							? existing.lessons.slice(0)
							: [];
						// Obtain a Set of all existing lesson IDs.
						const existingIdSet = new Set(
							merged.map((lesson) => readField("id", lesson))
						);

						const newLessons =
							incoming.lessons?.filter(
								(lesson) =>
									!existingIdSet.has(readField("id", lesson))
							) || [];

						if (!args?.first || args.first === 0) {
							merged.unshift(...newLessons);
						} else {
							merged.push(...newLessons);
						}

						return {
							...incoming,
							lessons: merged,
						};
					},
				},

				getUserNotifications: {
					keyArgs: [
						"s",
						"searchBy",
						"status",
						"startDate",
						"endDate",
						"group",
						"sort",
						"sortBy",
					],
					merge(
						existing: GetUserNotificationsData["getUserNotifications"],
						incoming: GetUserNotificationsData["getUserNotifications"],
						options: any
					) {
						const { readField, args } = options;
						// Slicing is necessary because the existing data is
						// immutable, and frozen in development.
						const merged: Notification[] = existing?.notifications
							? existing.notifications.slice(0)
							: [];
						// Obtain a Set of all existing course IDs.
						const existingIdSet = new Set(
							merged.map((course) => readField("id", course))
						);

						const newNotifs =
							incoming.notifications?.filter(
								(notif) =>
									!existingIdSet.has(readField("id", notif))
							) || [];

						if (!args?.first || args.first === 0) {
							merged.unshift(...newNotifs);
						} else {
							merged.push(...newNotifs);
						}

						return {
							...incoming,
							notifications: merged,
						};
					},
				},

				findAffiliatePayments: {
					keyArgs: ["sort"],
					merge(
						existing: FindAffiliatePaymentsData["findAffiliatePayments"],
						incoming: FindAffiliatePaymentsData["findAffiliatePayments"],
						options: any
					) {
						const { readField, args } = options;
						// Slicing is necessary because the existing data is
						// immutable, and frozen in development.
						const merged: PaymentBasic[] = existing?.payments
							? existing.payments.slice(0)
							: [];
						// Obtain a Set of all existing course IDs.
						const existingIdSet = new Set(
							merged.map((course) => readField("id", course))
						);

						const newNotifs =
							incoming.payments?.filter(
								(notif) =>
									!existingIdSet.has(readField("id", notif))
							) || [];

						if (!args?.first || args.first === 0) {
							merged.unshift(...newNotifs);
						} else {
							merged.push(...newNotifs);
						}

						return {
							...incoming,
							payments: merged,
						};
					},
				},

				findInvitedUsers: {
					keyArgs: ["sort", "searchBy", "s"],
					merge(
						existing: FindInvitedUsersData["findInvitedUsers"],
						incoming: FindInvitedUsersData["findInvitedUsers"],
						options: any
					) {
						const { readField, args } = options;
						// Slicing is necessary because the existing data is
						// immutable, and frozen in development.
						const merged: InvitedUser[] = existing?.invitedUsers
							? existing.invitedUsers.slice(0)
							: [];
						// Obtain a Set of all existing course IDs.
						const existingIdSet = new Set(
							merged.map((course) => readField("userId", course))
						);

						const newInvitedUser =
							incoming.invitedUsers?.filter(
								(notif) =>
									!existingIdSet.has(
										readField("userId", notif)
									)
							) || [];

						if (!args?.first || args.first === 0) {
							merged.unshift(...newInvitedUser);
						} else {
							merged.push(...newInvitedUser);
						}

						return {
							...incoming,
							invitedUsers: merged,
						};
					},
				},

				findPaymentRequests: {
					keyArgs: ["sort", "status", "sortBy"],
					merge(
						existing: FindPaymentRequestsData["findPaymentRequests"],
						incoming: FindPaymentRequestsData["findPaymentRequests"],
						options: any
					) {
						const { readField, args } = options;
						// Slicing is necessary because the existing data is
						// immutable, and frozen in development.
						const merged: PaymentRequest[] =
							existing?.paymentRequests
								? existing.paymentRequests.slice(0)
								: [];
						// Obtain a Set of all existing course IDs.
						const existingIdSet = new Set(
							merged.map((course) => readField("id", course))
						);

						const newPaymentRequests =
							incoming.paymentRequests?.filter(
								(notif) =>
									!existingIdSet.has(readField("id", notif))
							) || [];

						if (!args?.first || args.first === 0) {
							merged.unshift(...newPaymentRequests);
						} else {
							merged.push(...newPaymentRequests);
						}

						return {
							...incoming,
							paymentRequests: merged,
						};
					},
				},

				findReferrals: {
					keyArgs: ["sort", "sortBy", "s", "searchBy"],
					merge(
						existing: FindReferralsData["findReferrals"],
						incoming: FindReferralsData["findReferrals"],
						options: any
					) {
						const { readField, args } = options;
						// Slicing is necessary because the existing data is
						// immutable, and frozen in development.
						const merged: ReferralForAffiliate[] =
							existing?.referrals
								? existing.referrals.slice(0)
								: [];
						// Obtain a Set of all existing course IDs.
						const existingIdSet = new Set(
							merged.map((course) => readField("id", course))
						);

						const newReferrals =
							incoming.referrals?.filter(
								(notif) =>
									!existingIdSet.has(readField("id", notif))
							) || [];

						if (!args?.first || args.first === 0) {
							merged.unshift(...newReferrals);
						} else {
							merged.push(...newReferrals);
						}

						return {
							...incoming,
							referrals: merged,
						};
					},
				},

				/*lesson: {
          keyArgs: ["hasDetail", "courseId", "id"],
          merge(
            existing: SearchLessonData["searchLesson"],
            incoming: SearchLessonData["searchLesson"],
            options: any
          ) {
            const {
              readField,
              args
            } = options;

           console.log("----existing---", existing);
           console.log("----exisincomingting---", incoming);

           return incoming

          }
        }*/
			},
		},
		Sentence: {
			fields: {
				words: {
					// Non-normalized Author object within Book
					merge(existing, incoming, { mergeObjects }) {
						//console.log("---incoming0---", incoming);
						//console.log("---existing0---", incoming);
						return incoming; //mergeObjects(existing, incoming);
					},
				},
			},
		},
		Lesson: {
			fields: {
				sentences: {
					merge(existing, incoming, { mergeObjects }) {
						//console.log("---incoming---", incoming);
						//console.log("---existing---", incoming);
						return incoming;
					},
				},
			},
		},
		/*Sentence: {
      //keyFields: [ "words"],
      merge(
        existing: Sentence,
        incoming: Sentence,
        options: any
      ) {

        const {
          readField,
          args
        } = options;

        console.log("---incoming---", incoming);
        console.log("---existing---", existing);

        const newCommers: Word[] = incoming?.words ? incoming.words.slice(0) : [];
        // Obtain a Set of all existing lesson IDs.
        const newIdSet = new Set(
          newCommers.map(word => readField("id", word)));

        const existedWords = existing.words?.filter(
          word => !newIdSet.has(readField("id", word))) || [];

        return {
          ...incoming,
          words: [
            ...existedWords,
            ...newCommers
          ]
        };  
      }
    },*/
	},
});

const parseHeaders = (rawHeaders: any) => {
	const headers = new Headers();
	// Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
	// https://tools.ietf.org/html/rfc7230#section-3.2
	const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, " ");
	preProcessedHeaders.split(/\r?\n/).forEach((line: any) => {
		const parts = line.split(":");
		const key = parts.shift().trim();
		if (key) {
			const value = parts.join(":").trim();
			headers.append(key, value);
		}
	});
	return headers;
};

export const uploadFetch = (url: string, options: any) =>
	new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest();
		xhr.onload = () => {
			const opts: any = {
				status: xhr.status,
				statusText: xhr.statusText,
				headers: parseHeaders(xhr.getAllResponseHeaders() || ""),
			};
			opts.url =
				"responseURL" in xhr
					? xhr.responseURL
					: opts.headers.get("X-Request-URL");
			const body =
				"response" in xhr ? xhr.response : (xhr as any).responseText;
			resolve(new Response(body, opts));
		};
		xhr.onerror = () => {
			reject(new TypeError("Network request failed"));
		};
		xhr.ontimeout = () => {
			reject(new TypeError("Network request failed"));
		};
		xhr.open(options.method, url, true);

		Object.keys(options.headers).forEach((key) => {
			xhr.setRequestHeader(key, options.headers[key]);
		});

		if (xhr.upload) {
			xhr.upload.onprogress = options.onProgress;
		}

		options.onAbortPossible(() => {
			xhr.abort();
		});

		xhr.send(options.body);
	});

const customFetch = (uri: any, options: any) => {
	if (options.useUpload) {
		return uploadFetch(uri, options);
	}
	return fetch(uri, options);
};

const baseOptions: {
	errorPolicy?: ErrorPolicy;
} = { errorPolicy: "none" };

const authMiddleware = (loginAs?: string | null) =>
	new ApolloLink((operation, forward) => {
		const currentUserInfoStr = localStorage.getItem("currentUserInfo");
		const currentUserInfo = currentUserInfoStr
			? JSON.parse(currentUserInfoStr)
			: undefined;

		const resHeader = {
			"login-as": loginAs,
		};

		const loginAsHeader = loginAs ? resHeader : {};

		console.log("---operation.operationName---", operation.operationName);

		const selectedServer = selectTheBestServer(operation.operationName);

		const selectedServerUri = {
			uri: selectedServer + "/graphql",
		};

		// add the authorization to the headers
		operation.setContext(({ headers = {} }) => ({
			...(selectedServer ? selectedServerUri : {}),
			headers: {
				authorization: currentUserInfo?.token || null,
				"accept-language": i18n.locale || "en",
				...getLessonPagesHeaders(currentLessonRewardEarnedVar()),
				...loginAsHeader,
				...headers,
			},
		}));

		return forward(operation);
	});

async function initApolloClient(loginAs?: string | null) {
	await initializeVideosDownloadInfo(cache);

	// await before instantiating ApolloClient, else queries might run before the cache is persisted
	await persistCache({
		cache,
		storage: new LocalForageWrapper(localforage),
		// unlimited
		maxSize: false,
		/*
		it may cause performance issue so we remove it for now
		persistenceMapper: async (data: any) => {
			const filterData: any = (obj: any) => {
				if (Array.isArray(obj)) {
					return obj.map(filterData);
				} else if (obj && typeof obj === "object") {
					const filteredObj: any = {};
					for (const key in obj) {
						if (obj[key]?.__typename !== "DecksResultWithLesson") {
							filteredObj[key] = filterData(obj[key]);
						}
					}
					return filteredObj;
				}
				return obj;
			};

			// Filter the data
			const filteredData = JSON.stringify(filterData(JSON.parse(data)));

			// Log the filtered data (for debugging purposes)
			console.log("----persistenceMapper---", filteredData);

			return filteredData;
		},*/
	});

	const client = new ApolloClient({
		defaultOptions: {
			//watchQuery: baseOptions,
			query: baseOptions,
			mutate: baseOptions,
		},
		uri: GRAPHQL_URL,
		cache,
		link: from([
			authMiddleware(loginAs),
			onError((props) => {
				const { graphQLErrors, networkError } = props;

				let hasGeneralError = false;

				let isAuthError = false;

				let isPremuimError = false;

				if (graphQLErrors) {
					graphQLErrors.forEach(
						({ message, locations, path, extensions }) => {
							console.log(
								`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
							);
							if (extensions?.code === "UNAUTHENTICATED") {
								isAuthError = true;
							}

							if (message === "Not_Access_Premuim") {
								isPremuimError = true;
							}
							// Only notify the user if absolutely no data came back
							//All the error with status code 200 OK will be handle seperately
							//So here we handle general ones (4XX, 5XX, etc)
							hasGeneralError = true;
						}
					);
				}

				if (networkError) {
					console.log(`[Network error]: ${networkError}`);
					hasGeneralError = true;
				}

				if (hasGeneralError && !isPremuimError) {
					const args: {
						message: string;
						description: string;
						closeIcon: ReactNode;
						placement?: NotificationPlacement;
						style: CSSProperties;
					} = {
						message: translate("notification.error"),
						description: translate("commonErrors.tryAgain"),
						closeIcon: <CloseOutline />,
						placement: "topRight",
						style: {
							backgroundColor: "#fcd5cf",
						},
					};

					// notification.error(args);
				}

				if (networkError) {
					if (
						(networkError as ServerParseError | ServerError)
							.statusCode &&
						(networkError as ServerParseError | ServerError)
							.statusCode === 401
					) {
						isAuthError = true;
					}
				}

				// && history.location.pathname !== SIGNIN
				if (isAuthError === true) {
					userInfoVar(undefined);
					localStorage.removeItem("currentUserInfo");
					if ((window as any).ReactNativeWebAppLogout) {
						(window as any).ReactNativeWebAppLogout();
					}
					client.resetStore();
					//reset reactive vriables
					activeCourseTabVar(0);
					//  history.push(SIGNIN);
				}

				if (isPremuimError) {
					onlyPremiumAccessVar(true);
				}
			}),
			createUploadLink({
				uri: GRAPHQL_URL,
				//credentials: "same-origin",
				//headers: {
				//"keep-alive": "true"
				//},
				fetch: customFetch as any,
			}) as any,
			createPersistedQueryLink({ sha256: hash }),
		]), //process.env.API_URI
	});

	return client;
}

async function CheckAffiliate() {
	const info = await getUserTrackingInfo();

	// Define parameters
	const params = {
		country: info.country,
		ip: info.ip,
		platform: info.platform,
		version: info.version || "xxx",
		device: info.device,
		timeZone: info.timeZone,
	};

	// Create query string
	const queryString = new URLSearchParams(params).toString();

	try {
		// Fetch request
		const resp = await fetch(
			`${SERVER_URL}/user-tracking/check-affiliate?${queryString}`
		);

		const result = await resp.json();

		console.log("----result---", result);

		if (result.affiliate) {
			return result.affiliate;
		}
	} catch (err) {
		console.error("Error:", (err as any)?.message);
	}
}

// user tracking
//https://github.com/IntegerAlex/user-info-logger/tree/8f21218b01fa648b56d4cfd387bd2ae741625446
// import userInfo from 'user-info-logger'
// const data = userInfo()

const checkFindingAffiliate = (
	location: Location,
	currentUserInfo?: CurrentUser
) => {
	if (
		currentUserInfo?.affiliate ||
		currentUserInfo?.claimNoAffiliate === "yes"
	) {
		return;
	}

	const searchParams = new URLSearchParams(location.search);

	const affiliateCode = searchParams.get("affiliate-code") || "";
	const referrer = searchParams.get("referrer") || "";
	const affiliate = searchParams.get("affiliate") || "";
	const utmSource = searchParams.get("utm_source") || "";
	const utmMedium = searchParams.get("utm_medium") || "";
	console.log("----referrer---", referrer);

	let affiliateRef = "";
	let utmSourceRef = "";
	let utmMediumRef = "";

	if (referrer) {
		const referrerParams = new URLSearchParams(referrer);

		affiliateRef = referrerParams.get("affiliate") || "";
		utmSourceRef = referrerParams.get("utm_source") || "";
		utmMediumRef = referrerParams.get("utm_medium") || "";

		console.log(
			"----referrerParams---",
			utmMediumRef,
			utmSourceRef,
			affiliateRef
		);
	}

	if (affiliateCode) {
		// isRnAppVar(true);
		localStorage.setItem("affiliateCode", affiliateCode);
	} else if (affiliate) {
		localStorage.setItem("affiliateCode", affiliate);
	} else if (affiliateRef) {
		localStorage.setItem("affiliateCode", affiliateRef);
	} else {
		(async function () {
			const guessedAffiliate = await CheckAffiliate();
			console.log("---guessedAffiliate---", guessedAffiliate);
			if (guessedAffiliate) {
				localStorage.setItem("guessedAffiliateCode", guessedAffiliate);
			} else {
				const isRNCafeBazaar = (window as any)?.isRNCafeBazaar;
				if (isRNCafeBazaar) {
					localStorage.setItem("affiliateCode", "AGLVJ2");
				}
			}
		})();
	}
};

const App: React.FC = () => {
	const [isAppInitialized, setAppInitialized] = useState(false);
	const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();

	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);

	const isRnApp = searchParams.get("isRnApp") || "";
	const userLocale = searchParams.get("user-locale") || "";

	// console.log("userLocale---", userLocale);
	// console.log("affiliateCode---", affiliateCode);

	useEffect(() => {
		if (isRnApp) {
			// isRnAppVar(true);
			localStorage.setItem("isRnApp", "yes");
		}
	}, [isRnApp]);

	useEffect(() => {
		if (userLocale) {
			// isRnAppVar(true);
			localStorage.setItem("userLocale", userLocale);
		}
	}, [userLocale]);

	const { initIncompleteRegister } = useIncompleteRegister();

	useEffect(() => {
		initIncompleteRegister();
		const lastVerifyRequestTimePrev = localStorage.getItem(
			"lastVerifyRequestTime"
		);
		if (lastVerifyRequestTimePrev) {
			lastVerifyRequestTimeVar(Number(lastVerifyRequestTimePrev));
		}
	}, []);

	// login-as

	useEffect(() => {
		async function init() {
			const loginAs = searchParams.get("login-as") || null;

			const newClient = await initApolloClient(loginAs);

			setClient(newClient);
		}

		init();
	}, []);

	//useRewardAdsStorage();

	useEffect(() => {
		const currentUserInfoStr = localStorage.getItem("currentUserInfo");
		const currentUserInfo: CurrentUser | undefined = currentUserInfoStr
			? JSON.parse(currentUserInfoStr)
			: undefined;
		userInfoVar(currentUserInfo);

		if (currentUserInfo) {
			const subtitleLastFrame = localStorage.getItem(
				"subtitleLastFrame_" + currentUserInfo.id
			);
			subtitleLastFrameVar(JSON.parse(subtitleLastFrame || "{}"));
		}
		translationLanguageVar(currentUserInfo?.nativeLanguage || "");

		checkFindingAffiliate(location as any, currentUserInfo);

		if (isIOS) {
			document.body.classList.add("ios-app");
			document.getElementById("root")?.classList.add("ios-app");
			document.querySelector("html")?.classList.add("ios-app");
		}
		if ((window as any).ReactNativeWebAppInitialized) {
			const dupUser = JSON.stringify({
				...currentUserInfo,
			});
			(window as any).ReactNativeWebAppInitialized(
				currentUserInfo ? dupUser : undefined
			);
		}
	}, []);

	const lessonUploadingInfo = useReactiveVar(lessonUploadingInfoVar);
	const lessonFromUrlInfo = useReactiveVar(lessonFromUrlInfoVar);
	const pullToRefreshDisabled = useReactiveVar(pullToRefreshDisabledVar);

	const onRefresh = async () => {
		await client?.resetStore();
	};

	/**
	 * send user info from react native
	 */
	useReactNativeUserSync();

	useEffect(() => {
		let isUnMounted = false;
		async function init() {
			if (isUnMounted) {
				return;
			}
			try {
				const country = await getUserCountry();
				console.log("---country---", country);
				if (country.name === "Iran") {
					i18n.locale = "fa-IR";
					isRTLVar(true);
					import("./rtl-style.css"); // Adjust the path to your RTL CSS file
				}
			} catch (err) {
				console.error(err);
			} finally {
				setAppInitialized(true);
			}
		}

		const currentUserInfoStr = localStorage.getItem("currentUserInfo");

		const currentUserInfo: CurrentUser | undefined = currentUserInfoStr
			? JSON.parse(currentUserInfoStr)
			: undefined;

		console.log(
			"currentUserInfo?.nativeLanguage---",
			currentUserInfo?.nativeLanguage
		);

		const userLocale = localStorage.getItem("userLocale");

		if (
			currentUserInfo?.nativeLanguage === "fa" ||
			userLocale === "fa-IR"
		) {
			i18n.locale = "fa-IR";
			isRTLVar(true);
			import("./rtl-style.css");
			setAppInitialized(true);
		} else {
			init();
		}

		return () => {
			isUnMounted = true;
		};
	}, []);

	const isRTL = useReactiveVar(isRTLVar);

	useEffect(() => {
		notification.config({
			//placement: 'bottomRight',
			//bottom: 50,
			// duration: 3,
			rtl: isRTL,
			maxCount: 2,
		});
	}, [isRTL]);

	useOptimalServerFactory();

	if (!isAppInitialized || !client) {
		return <Loading isLoading />;
	}

	/*if (i18n.locale === "fa-IR") {
		return (
			<div className="iran-sanction">
				<p>Dear User,</p>
				<p>
					Due to sanctions imposed by the United States, you are
					unable to access this application from Iran.
				</p>
				<p>We appreciate your understanding and patience.</p>
			</div>
		);
	}*/

	/*//#endregion} */
	return (
		<>
			{/*<PullToRefresh
      onRefresh={onRefresh}
      completeText="Done!"
      pullingText={<IconWrapper name="refresh" />}
      refreshingText="Refreshing..."
      canReleaseText="Start to refresh..."
      disabled={pullToRefreshDisabled}
  ></PullToRefresh>*/}
			<ApolloProvider client={client}>
				<main
					id="main-app-element"
					className={clsx("App", isIOS && "iosApp")}
				>
					{NEED_TO_UPGRADE_DATABASE && <DatabaseMigration />}
					<Routes />
					{(lessonUploadingInfo.isStarted ||
						lessonFromUrlInfo.isStarted) && (
						<AddLessonInBackground />
					)}
					<ReactNativeBackButton />
					<ReactNativeForceUpdate />
					<ReactNativeSetCurrentUrl />
					<ReactNativeReady />
					{/*isIOS ||
					isAndroid ||
					((window as any).isReactNativeApp &&
						(window as any).isReactNativeApp &&
						(window as any).isReactNativeNewArchitectFlag) ? (
						<RnUserAchievementsCount />
						) : null*/}
					{isIOS ||
					isAndroid ||
					((window as any).isReactNativeApp &&
						(window as any).isReactNativeApp &&
						(window as any).isReactNativeNewArchitectFlag) ? (
						<ReactNativeTakeAction />
					) : null}
					<CheckLanguageChange />

					<RNVideoDownloader />

					<RNTextToSpeechVoices />
				</main>
			</ApolloProvider>
		</>
	);
};

export default App;
