import { useReactiveVar } from "@apollo/client";
import { useNetworkStatus } from "./networks-status";
import { isRTLVar } from "../App";
import { usePing } from "./ping-monitor";
import { useMonitorPacketLoss } from "./packet-loss-monitor";
import { useCheckHealthyServers } from "./server-health-checker";
import { useLocationChecker } from "./location-checker";

export function useOptimalServerFactory() {
	const isRTL = useReactiveVar(isRTLVar);

	// #1: what is the user country?
	// check the user country every 30 seconds
	// check the user country after changing the routes
	// check the user country when app is initialized
	// check the user country when the networks switch from offline to online or vice versa.
	// check the user country when connection is changed (not reliable) -- not supported in safari and ios webviews
	// save the user country in reactive vars
	useLocationChecker();

	// #2: which servers are active?
	// check the servers health every 5 seconds
	useCheckHealthyServers();

	// #3: is database healthy?
	// -- if no we should show error

	// #4: showing network status to user
	// is it offline? we should show warning to user
	useNetworkStatus(isRTL);

	// #5: is User network unstable?
	// if yes we should show warning to user
	usePing();
	useMonitorPacketLoss();

	// #6: which server should I use to send request?
	// first we should check the OPERATION name in apollo
	// -- if just one server supports it, we just choose that server
	// ---- if that server is down we should show an error
	// -- if both servers support it, we choose the best server.
	// ---- 1- we should check if both servers are active.
	// ------ if yes, we use the user country to decide which server to use
	// ------ if no, we use the active server

	// #7: which AWS bucket should I use to send request?
	// check the active Aws servers
	// we should check if both servers are active
	// ------ if yes, we use the user country to decide which server to use
	// ------ if no, we use the active server
}
